<template>
  <div class="md-editor">
    <mavon-editor
      ref="editor"
      :editable="!disabled"
      :placeholder="placeholder"
      :value="value"
      @imgAdd="uploadEditorImage"
      @imgDel="removeEditorImage"
      @change="change"
    />
    <el-upload
      ref="upload"
      style="display: none;"
      :action="action"
      :file-list="fileList"
      :on-change="uploadChange"
      :on-success="uploadSuccess"
      :on-error="uploadError"
    />
  </div>
</template>

<script>
import {Component, Vue, Prop, Model} from 'vue-property-decorator';
import * as api from './md-editor.api';

export default @Component({name: 'editor'})
class MdEditor extends Vue {
  @Model('valueChange', {default: ''}) value;
  @Prop() disabled;
  @Prop() placeholder;
  @Prop({default: api.getUploadApiUrl()}) action;

  fileList = [];
  uploadedFiles = [];
  currentImagePosition = 0;

  mounted() {
  }

  change(markdown, render) {
    // 检测一下上传过的图片，在内容中还存不存在，如果不存在则删除
    this.uploadedFiles = this.uploadedFiles.filter(file => {
      const exist = markdown.includes(file.url);

      // if (!exist) {
      //   api.removeEditorImage(file.url.replace(api.getOSSApiUrl(), ''));
      // }

      return exist;
    });

    this.$emit('valueChange', markdown);
    this.$emit('change', {
      markdown: JSON.stringify(markdown),
      html: render,
    });
  }

  uploadChange(file, fileList) {
    this.fileList = fileList;
  }

  uploadSuccess(response, file, fileList) {
    this.uploadedFiles.push(response);
    this.$refs.editor.$img2Url(this.currentImagePosition, response.url);
  }

  uploadError(err, file, fileList) {
    this.$message.error('文件上传失败: ' + JSON.stringify(err));
  }

  uploadEditorImage(position, file) {
    this.currentImagePosition = position;
    this.$refs.upload.uploadFiles.push(file);
    this.$refs.upload.$refs['upload-inner'].upload(file);
  }

  removeEditorImage(filename) {
    // 没触发
  }
}
</script>

<style lang="scss">

</style>
