<template>
  <div class="page-spec-agent-editor">
    <spec-editor/>
  </div>
</template>

<script lang="js">
import SpecAgentEditorPage from './spec-agent-editor';

export default SpecAgentEditorPage;
</script>

<style lang="scss">
@import "./spec-agent-editor";
</style>
