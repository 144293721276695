import { Component, Vue } from 'vue-property-decorator';
import SpecEditor from '../spec-merchant-editor/spec-merchant-editor.vue';

@Component({
  components: { SpecEditor }
})
class SpecAgentEditorPage extends Vue {
  title = 'spec-agent-editor';
}

export default SpecAgentEditorPage;
