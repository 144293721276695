import request from "@/utils/request";
import {api} from '@/libs/common/domain';

export function removeEditorImage(file_path) {
  return request({
    url: `${api.base}/uploaders`,
    method: 'delete',
    params: {
      file_path
    },
  })
}

export function getUploadApiUrl() {
  return `${api.base}/uploaders`;
}

export function getOSSApiUrl() {
  return `https://statics.zhangyujiaqi.com`;
}
