<template>
  <div class="container">
    <el-form :model="articleForm" :rules="articleRules" ref="articleForm" label-width="100px">
      <el-form-item label="文章名称" prop="article_name" style="width: 500px">
        <el-input v-model="articleForm.article_name"></el-input>
      </el-form-item>
      <!-- <el-form-item label="文章分类" prop="category_id">
        <el-cascader
          :options="articleCategoryTree"
          :props="{children: 'children',label: 'name',value: 'id'}"
          :show-all-levels="false"
          :value="defaultCascaderValue"
          @change="handleCascaderChange"
          change-on-select
        ></el-cascader>
      </el-form-item>-->
      <!-- <el-form-item label="文章排序" prop="sort">
        <el-input-number v-model="articleForm.sort" controls-position="right" :min="0" :max="99999"></el-input-number>
      </el-form-item>
      <el-form-item label="文章外链" prop="outside_url" style="width: 500px">
        <el-input v-model="articleForm.outside_url"></el-input>
      </el-form-item>-->
      <el-form-item label="文章内容" prop="content">
        <md-editor v-model="mdContent" @change="setContent"></md-editor>
      </el-form-item>
      <el-form-item label>
        <el-button type="primary" @click="submitArticleForm">保存文章</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as API_Article from "@/api/article";
import MdEditor from '@/components-new/md-editor/md-editor.vue';

export default {
  name: "addArticle",
  components: {
    MdEditor,
  },
  // components: { UE },
  data() {
    const checkContent = (rule, value, callback) => {
      // console.log(rule, value, callback);
      if (!this.articleForm.content) {
        return callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };
    const {article_id, category} = this.$route.params;
    return {
      mdContent: '',
      // 如果是修改，则有值
      article_id,
      // 分类树
      articleCategoryTree: category,
      // 添加、修改文章 表单
      articleForm: {
        article_name: "",
        content: "",
        content_md: '',
        category_id: 497, // 此处写死为规范的id
      },
      // 添加、修改文章 表单规则
      articleRules: {
        article_name: [this.MixinRequired("请输入文字名称！")],
        category_id: [this.MixinRequired("请选择文章分类！")],
        // content: [this.MixinRequired("请输入文章内容！")],
        content: [{validator: checkContent}],
      },
      // 级联选择器默认值
      defaultCascaderValue: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.article_id = to.params.article_id;
    next();
  },
  activated() {
    this.article_id = this.$route.params.article_id;
  },
  watch: {
    article_id: "Get_ArticleData",
  },
  mounted() {
    if (this.article_id) this.Get_ArticleData();
  },
  methods: {
    setContent(e) {
      this.articleForm.content = e.html;
      this.articleForm.content_md = e.markdown;
    },
    /** 当分类改变时 */
    handleCascaderChange(data) {
      this.articleForm.category_id = data[data.length - 1];
    },
    /** 添加、编辑文章 表单提交 */
    submitArticleForm() {
      // this.articleForm.content = this.$refs["ue"].getUEContent();
      if (window.location.href.indexOf('spec-platform-editor') >= 0) {
        this.articleForm.category_id = 498;
      } else if (window.location.href.indexOf('spec-agent-editor') >= 0) {
        this.articleForm.category_id = 499;
      }
      this.$refs["articleForm"].validate((valid) => {
        if (valid) {
          const saveScuess = () => {
            this.$message.success("保存成功！");
            this.$route.params.callback && this.$route.params.callback();
            if (window.location.href.indexOf('spec-platform-editor') >= 0) {
              this.$parent.$parent.ctrl.routerTab.closeTab({
                name: '平台规范编辑',
                selected: true
              });
            } else if (window.location.href.indexOf('spec-agent-editor') >= 0) {
              this.$parent.$parent.ctrl.routerTab.closeTab({
                name: '代理规范编辑',
                selected: true
              });
            } else {
              this.$parent.ctrl.routerTab.closeTab({
                name: '商户规范编辑',
                selected: true
              });
            }
          };
          const {article_id} = this;
          if (article_id) {
            API_Article.editArticle(article_id, this.articleForm).then(
              saveScuess
            );
          } else {
            API_Article.addArticle(this.articleForm).then(saveScuess);
          }
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    /** 获取文章详情 */
    Get_ArticleData() {
      API_Article.getArticleDetail(this.article_id).then((response) => {
        this.articleForm = response;
        this.mdContent = response.content_md ? JSON.parse(response.content_md) : '';

        let d = [];
        const {category_id} = response;
        this.articleCategoryTree.forEach((item) => {
          if (item.id === category_id) {
            d = [item.id];
          } else {
            item.children && item.children.forEach((_item) => {
              if (_item.id === category_id) {
                d = [item.id, _item.id];
              }
            });
          }
        });
        this.defaultCascaderValue = d;
      });
    },
  },
};
</script>
